import {
  LocalNotifications,
  PermissionStatus,
  ScheduleResult
} from '@capacitor/local-notifications'

interface LocalNotificationPermission extends PermissionStatus {
  isGranted: boolean,
}

interface MessageData {
  thread: string,
  channelSid: string,
  tenantId: string,
  phoneNumberId: string
}

interface ICapacitorLocalNotification {
  checkOrRequestPermission: () => Promise<LocalNotificationPermission>
  checkPermission: () => Promise<LocalNotificationPermission>
  setInlineNotificationsListener: (callback: () => void) => void
  toastMessageNotification: (
    text: string,
    title: string,
    messageData: MessageData
  ) => Promise<ScheduleResult>
  registerNotifications: () => Promise<void>
}

export default class CapacitorLocalNotification implements ICapacitorLocalNotification {
  async checkPermission () {
    const permission = await LocalNotifications.checkPermissions()
    return { ...permission, isGranted: permission.display === 'granted' }
  }

  async checkOrRequestPermission () {
    const permission = await this.checkPermission()
    if (permission.isGranted) { return permission }
    const requestedPermission = await LocalNotifications.requestPermissions()
    return { ...requestedPermission, isGranted: requestedPermission.display === 'granted' }
  }

  setInlineNotificationsListener (callback: () => void) {
    LocalNotifications.removeAllListeners()
    LocalNotifications.addListener('localNotificationActionPerformed', callback)
  }

  toastMessageNotification (text: string, title: string, messageData: MessageData) {
    return LocalNotifications.schedule({
      notifications: [
        {
          id: Math.floor(Math.random() * 100000),
          title,
          body: text,
          extra: {
            data: { ...messageData }
          },
          iconColor: '#2C6068',
          actionTypeId: 'MSG_NOTIFICATION'
        }
      ]
    })
  }

  registerNotifications () {
    return LocalNotifications.registerActionTypes({
      types: [
        {
          id: 'MSG_NOTIFICATION',
          actions: [
            {
              id: 'dismiss',
              title: 'Dismiss',
              destructive: true
            }
          ]
        }
      ]
    })
  }
}
